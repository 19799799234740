import React from 'react'
import axios from 'axios'
import { OrganizationJsonLd } from 'next-seo'
import Layout from '../../src/components/layouts'
import defaultSEO from '../../src/common/constants/nextSEO'
import CustomJsonLd from '../../src/components/shared/CustomJsonLd'
import OutageBannerRepository from '../../src/repositories/OutageBannerRepository'
import logger from '../../src/common/logger'
import { APP_BASE_PATH } from '../../src/common/constants'
import { data } from '../../src/components/pages/Home/consts'
import { jsonLdData } from '../../src/common/constants/jsonLd'
import HomePage from '../../src/components/pages/Home/variant-5'
import WithSeoOverrides from 'src/components/shared/WithSeoOverride'

export async function getServerSideProps(context) {
  const { TRUSTPILOT_API_KEY, TRUSTPILOT_BUSINESS_ID } = process.env
  let outageBanner, reviewsRes
  try {
    outageBanner = await OutageBannerRepository.getInstance().getModels(
      {},
      context?.preview
    )
    reviewsRes = await axios.get(
      `https://api.trustpilot.com/v1/business-units/${TRUSTPILOT_BUSINESS_ID}/reviews`,
      {
        params: {
          apikey: TRUSTPILOT_API_KEY,
          stars: '4,5',
          tagValue: 'Compliance-approved',
        },
      }
    )
  } catch (error) {
    logger.error('Home page: Ups something bad happens', {
      error,
    })
  }

  return {
    props: {
      outageBanner: outageBanner || [],
      reviews: reviewsRes?.data?.reviews || [],
      homeVariation: 'variant-5',
    },
  }
}

// Potential offer banner with extra content.
const Variant5 = (pageProps) => {
  const { outageBanner, homeVariation, reviews } = pageProps
  const bannerData = outageBanner && outageBanner[0]
  const isAvailable = bannerData?.isAvailable || false
  const message = bannerData?.enMessage || ''
  const JSON_LD_DATA = {
    type: 'Organization',
    id: `${APP_BASE_PATH()}/#organization`,
    logo: `${APP_BASE_PATH()}/next-assets/fdr-logo.webp`,
    legalName: 'Freedom Debt Relief LLC',
    name: 'Freedom Debt Relief',
    address: {
      streetAddress: '1875 S Grant St #400',
      addressLocality: 'San Mateo',
      addressRegion: 'CA',
      postalCode: '94402',
      addressCountry: 'US',
    },
    sameAs: [
      'https://twitter.com/freedomdebt',
      'https://www.facebook.com/freedomdebtrelief/',
      'https://www.linkedin.com/company/freedom-debt-relief/',
      'https://www.youtube.com/freedomdebtrelief',
      'https://www.instagram.com/freedomdebtrelief/',
    ],
    contactPoints: [
      {
        type: 'ContactPoint',
        telephone: '+18009100065',
        contactType: 'customer service',
      },
    ],
    url: APP_BASE_PATH(),
  }

  data.heroBanner.content.number = '833-582-2310'

  return (
    <>
      <WithSeoOverrides {...defaultSEO} />
      <OrganizationJsonLd {...JSON_LD_DATA} />
      <CustomJsonLd
        id={'webPage'}
        data={jsonLdData(defaultSEO?.title, defaultSEO?.description, '/')}
      />
      <Layout isBrandPage={true} isHome={true} homeVariation={homeVariation}>
        <HomePage
          data={data}
          outageBanner={{ isAvailable, message }}
          homeVariation={homeVariation}
          reviews={reviews}
        />
      </Layout>
    </>
  )
}

export default Variant5
