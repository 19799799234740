import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Img from 'src/components/shared/Img'
import SliderCTA from '../SliderCTA'

function HeroBannerFooter({
  headerInfo,
  homeVariation,
  isSimpleView = false,
  sliderProps = {},
  className,
}) {
  return (
    <div
      data-testid="hero-banner-c"
      className={classNames(
        'flex flex-col items-center justify-center p-20 px-4',
        className,
        isSimpleView ? 'bg-blue-135' : 'bg-gray-25'
      )}
    >
      <h1
        className={classNames(
          'pt-10 text-center text-heading-3 font-semibold text-black-font sm:text-36 sm:font-[500] lg:pl-12 lg:pt-0 xl:ml-10 xl:pl-4'
        )}
      >
        {headerInfo?.title}
      </h1>
      <div className="2xl:2/5 flex flex-col items-center justify-center sm:w-full md:w-2/5 lg:w-2/5 xl:w-2/5">
        <div className="my-3 flex flex-col justify-center xl:ml-10 xl:w-3/4 xl:pl-12">
          {headerInfo?.list?.map((text, index) => (
            <div
              key={`hero-item-${index}`}
              className={`flex items-center py-1 ${
                isSimpleView ? 'ml-10' : ''
              }`}
            >
              <Img
                dataTestId="check-hero-item"
                alt="checkIcon"
                src="/next-assets/icons/check-green.svg"
                className="h-[18px] w-[15px]"
                styles={{ position: 'relative' }}
                priority
              />
              <span
                className={`sm:text-bold ml-2 ${
                  isSimpleView
                    ? 'sm:text-heading-2.5 text-black-font'
                    : 'sm:text-heading-3.5'
                } text-15`}
              >
                {text}
              </span>
            </div>
          ))}
        </div>
      </div>
      <SliderCTA
        hiddeTitle
        variation={homeVariation}
        {...sliderProps}
        className={classNames('sm:w-[430px]', sliderProps.className)}
      />
    </div>
  )
}

HeroBannerFooter.propTypes = {
  isDefaultLang: PropTypes.bool,
  headerInfo: PropTypes.object,
  className: PropTypes.string,
  isSimpleView: PropTypes.bool,
  sliderProps: PropTypes.object,
}

export default HeroBannerFooter
